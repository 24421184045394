<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Alerts Variables</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/alerts_variables/create">
          <v-icon left dark>mdi-plus</v-icon>Add New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>

            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="variables"
              :search="search"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <template v-slot:item.id="{ item }">
                <v-btn color="primary" text :to="`/alert_variables/${item.id}`">{{
                  item.id
                }}</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      variables: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Key", value: "variable_key" },
        { text: "Value", value: "variable_value" },
        { text: "Created at", value: "created_at" },
      ],
    };
  },
  methods: {
    getVariables() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/alert/variables`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.variables = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getVariables();
  },
};
</script>

<style scoped>
</style>